<template>
    <v-row
        v-if="profile.typeEntity != 'B' && (profile.facebookprofile || profile.instagramprofile || (profile.videos != null && profile.videos.length > 0) || profile.tiktok)">
        <v-col cols="12" v-if="profile.typeEntity != 'B'" class="pb-0">
            <span class="text-button font-weight-medium">Social media:</span>
        </v-col>
        <v-col cols="12" class="pt-0 pb-0"
            v-if="profile.typeEntity != 'B' && (profile.facebookprofile || profile.instagramprofile || (profile.videos != null && profile.videos.length > 0) || profile.tiktok)">
            <v-divider></v-divider>
        </v-col>
        <v-col cols="12" v-if="profile.typeEntity != 'B' && !loading" class="pt-0">
            <v-row align-content="stretch" justify="start">
                <v-col cols="1" v-if="profile.facebookprofile">
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn color="blue" v-bind="attrs" v-on="on" icon :href="profile.facebookprofile"
                                target="_blank">
                                <v-icon size="24px" color="primary">mdi-facebook</v-icon>
                            </v-btn>
                        </template>
                        <span>Facebook</span>
                    </v-tooltip>
                </v-col>
                <v-col cols="1" v-if="profile.instagramprofile">
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn color="#D93F8FFF" v-bind="attrs" v-on="on" icon :href="profile.instagramprofile"
                                target="_blank">
                                <v-icon size="24px">mdi-instagram</v-icon>
                            </v-btn>
                        </template>
                        <span>Instagram</span>
                    </v-tooltip>
                </v-col>
                <v-col cols="1" v-if="profile.videos != null && profile.videos.length > 0">
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn color="red" v-bind="attrs" v-on="on" icon :href="profile.videos[0].url"
                                target="_blank">
                                <v-icon size="24px">mdi-youtube</v-icon>
                            </v-btn>
                        </template>
                        <span>Youtube</span>
                    </v-tooltip>
                </v-col>
                <v-col cols="1" v-if="profile.website">
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn color="blue" v-bind="attrs" v-on="on" icon :href="profile.website" target="_blank">
                                <v-icon size="24px" color="primary">mdi-web</v-icon>
                            </v-btn>
                        </template>
                        <span>Website</span>
                    </v-tooltip>
                </v-col>
                <v-col v-if="profile.tiktok" cols="1">
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs" v-on="on" :href="profile.tiktok" color="red" icon target="_blank">
                                <img src="@/assets/images/icons/ticktock.png" alt="icon" width="25" height="25">
                            </v-btn>
                        </template>
                        <span>Tiktok</span>
                    </v-tooltip>
                </v-col>
            </v-row>
        </v-col>
        <v-col class="d-flex" v-if="loading">
            <v-skeleton-loader class="mr-2" type="avatar"></v-skeleton-loader>
            <v-skeleton-loader class="mr-2" type="avatar"></v-skeleton-loader>
            <v-skeleton-loader class="mr-2" type="avatar"></v-skeleton-loader>
            <v-skeleton-loader class="mr-2" type="avatar"></v-skeleton-loader>
            <v-skeleton-loader class="mr-2" type="avatar"></v-skeleton-loader>
        </v-col>
    </v-row>
</template>
<script>
export default {
    name: "SocialMedia",
    props: {
        profile: {
            type: Object,
            required: true
        },
        loading: {
            type: Boolean,
            default: false
        }
    }
}
</script>
