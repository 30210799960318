<template>
    <GmapMap :center="location" :zoom="18" :map-type-id="typeMap" style="width: 100%; height: 400px">
        <GmapMarker :position="location" :clickable="true"
            :draggable="false" @click="center = location" />
    </GmapMap>
</template>
<script>
export default {
    name: "Map",
    props: {
        location: {
            type: Object,
            required: true
        },
        typeMap: {
            type: String,
            default: "roadmap"
        }
    }
}
</script>